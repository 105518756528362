import React from "react";

import banner from "../../../../../assets/images/agent-banner.png";

const OnboardingImage = () => {
  return (
    <div className="h-full w-1/2 maxLg:w-full maxLg:absolute maxLg:left-0 maxLg:right-0 maxLg:top-0">
      <img src={banner} alt="banner" className="w-full h-screen object-cover" />
    </div>
  );
};

export default OnboardingImage;
