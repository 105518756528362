import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";

const PortalWrapper = React.forwardRef(({ children }, ref) => {
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);
  const [position, setPosition] = useState({
    left: 0,
    top: 0,
    width: "100%",
  });

  const dropdownRef = useRef(null);

  const handleDropdDownClick = () => {
    setOpenDropdownMenu(!openDropdownMenu);
    if (ref && "current" in ref && ref.current) {
      const { top, left, height } = ref.current.getBoundingClientRect();
      setPosition({
        top: top + height,
        left,
        width: `${ref.current.clientWidth}px`,
      });
    }
  };

  useEffect(() => {
    if (openDropdownMenu && dropdownRef.current) {
      dropdownRef.current.style.top = `${position.top}px`;
      dropdownRef.current.style.left = `${position.left}px`;
      dropdownRef.current.style.display = "none";
    }
  }, [openDropdownMenu, position]);

  return (
    // className="w-full"
    <div ref={ref} onClick={handleDropdDownClick}>
      {children(position)}
      {openDropdownMenu &&
        ReactDOM.createPortal(
          <div ref={dropdownRef} style={{ position: "absolute" }}>
            {children(position)}
          </div>,
          document.body
        )}
    </div>
  );
});
export default PortalWrapper;
