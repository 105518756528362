import React from 'react'
import ResetPassword from '../../../../shared/auth/reset-password/ResetPassword'
import { resetPassword } from '../../../../shared/api/agent'
import { useParams } from 'react-router-dom'

const AgentResetPassword = () => {

  const { email } = useParams();

  return (
    <ResetPassword
      onSubmit={resetPassword}
      loginUrl='/agents/login'
      email={email}
    />
  )
}

export default AgentResetPassword