/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

import Styles from "../../../assets/css/navigation.module.css";

import { setToken } from "../../../shared/helpers/util";
import { svgLogo } from "../../../shared/api/data";
import { homeownerCheckPassword } from "../../../shared/api/homehub";
import Loader from "../../../shared/components/loader/Loader";

import OverlayStaticDetails from "./OverlayStaticDetails";
import CheckPasswordModal from "./CheckPasswordModal";

import close from "../../../assets/images/closeSideMenu.svg";
import { toast } from "react-toastify";

const CheckPasswordModalWrapper = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const uniqueHash = params.get("unique_hash");

  useQuery(["check-password"], () => homeownerCheckPassword(uniqueHash), {
    onSuccess: (res) => {
      setIsLoading(false);
      if (res.data.token) {
        setToken("Token", res?.data?.token);
        navigate(`/property/${res?.data?.property_id}/details`);
      }
      if (res.data.success === true) {
        setIsOpenModal(true);
      }
    },
    onError: (error) => {
      toast(error.response.data.message, { type: "error" });
      navigate(`/buyers/login`);
    },
  });

  return (
    <div className="flex maxMd:flex-col">
      <header
        className={clsx(
          "flex items-center fixed bg-yankeesblue pl-[23px] pr-[36px] h-[65px] w-full z-[123] justify-between"
        )}
      >
        <a href="#">
          <img
            src="https://i.ibb.co/wzQSpys/image.png"
            alt="image"
            border="0"
            className="max-w-[125px]"
          />
        </a>
        <a href="#">
          <img
            src="https://i.ibb.co/926XH5B/image.png"
            alt="image"
            border="0"
          />
        </a>
      </header>
      {isLoading ? ( // Render loading state
        <Loader className="h-screen w-full justify-center items-center flex text-2xl loader-content" />
      ) : (
        <div className="desktop-navigation">
          <div className={Styles.parent}>
            <div className={Styles.container}>
              <button className="w-[24px] h-[24px]">
                <img
                  src={close}
                  alt="close-img"
                  className="md:hidden w-6 h-6"
                />
              </button>

              <div className={Styles.nav}>
                <Link to="/" className={"nav-item"}>
                  <div className={Styles.tooltip}>
                    <span className={clsx(Styles.tooltiptext)}>home</span>
                    {svgLogo.dashboard}
                  </div>
                </Link>
                <Link to="property/search" className={"nav-item"}>
                  <div className={Styles.tooltip}>
                    <span className={Styles.tooltiptext}>Search</span>
                    {svgLogo.searchProperty}
                  </div>
                </Link>

                <Link to="/my-contacts" className={"nav-item"}>
                  <div className={Styles.tooltip}>
                    <span
                      className={clsx(Styles.tooltiptext, "tooltip__property")}
                    >
                      My Homeowners
                    </span>
                    {svgLogo.homeowner}
                  </div>
                </Link>
                <Link to="/messages" className={"nav-item"}>
                  <div className={Styles.tooltip}>
                    <span
                      className={clsx(Styles.tooltiptext, "tooltip__messages")}
                    >
                      Messages
                    </span>

                    {svgLogo.chatMessageIcon}
                  </div>
                </Link>

                <Link to="properties" className={"nav-item"}>
                  <div className={Styles.tooltip}>
                    <span className={clsx(Styles.tooltiptext)}>
                      Properties Listing
                    </span>
                    {svgLogo.myProperty}
                  </div>
                </Link>

                <Link to="leads" className={"nav-item"}>
                  <div className={Styles.tooltip}>
                    <span className={Styles.tooltiptext}>
                      Homeowner Request
                    </span>
                    {svgLogo.leads}
                  </div>
                </Link>
                <hr />

                <Link className={clsx(Styles.user)}>
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    className="!h-[22px] !w-[22px]"
                  />
                  <span
                    className={clsx(Styles.logout, "nav-item cursor-pointer")}
                  >
                    Logout
                  </span>
                </Link>
              </div>
            </div>
          </div>

          <OverlayStaticDetails />
        </div>
      )}
      {isOpenModal && <CheckPasswordModal />}
    </div>
  );
};

export default CheckPasswordModalWrapper;
