import React from "react";

import { useQuery } from "@tanstack/react-query";

import { ReactComponent as BrandLogo } from "../../../../../src/assets/images/logo4.svg";
import { ReactComponent as PaymentCancleIcon } from "../../../../assets/images/agent-icons/cancle-icon.svg";
import { retrieveDomain } from "../../../../shared/api/agent";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../../../shared/components/loader/Loader";

const PaymentCancel = () => {
  const [params] = useSearchParams();
  const sessionId = params.get("session_id");
  const navigate = useNavigate();

  const { data: retriveDomainData, isLoading: isLoadingRetriveDomain } =
    useQuery(
      ["get-domain"],
      () => retrieveDomain({ session_id: sessionId, cancel: true }),
      {
        enabled: !!sessionId,
      }
    );

  const onClickLoginNow = () => {
    if (process.env.REACT_APP_DEVELOPMENT_ENVIRONMENT === "local") {
      navigate("agent/login");
    } else {
      window.location.replace(retriveDomainData.data.login_url);
    }
  };

  return (
    <div className="flex flex-col h-screen justify-center items-center gap-8">
      <BrandLogo />

      <div className="modal-card border-[0.063rem] rounded-lg bg-white border-solid py-10 maxMd:py-5 px-16 maxMd:px-6 md:!max-w-xl shadow-congratModal w-full max-h-[381px] h-full">
        {isLoadingRetriveDomain ? (
          <Loader className="flex justify-center items-center h-full" />
        ) : (
          <>
            <div className="flex justify-center">
              <PaymentCancleIcon />
            </div>
            <div className="mt-4 text-center">
              <h1 className="text-[26px] maxMd:text-xl maxSm:!text-base text-metallicBlue font-bold block">
                Payment Cancelled
              </h1>
              <p className="text-coolGrey mt-2 text-base maxMd:text-sm">
                We regret to inform you that your recent payment transaction has
                been cancelled.
              </p>
            </div>
            <div className="text-center">
              <button
                className="!bg-metallicBlue text-white w-full p-4 maxMd:p-2 rounded-md mt-8 hover:opacity-[0.9] transition max-w-[200px]"
                onClick={onClickLoginNow}
              >
                Login Now
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentCancel;
