import React, { Suspense } from "react";

import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-tooltip/dist/react-tooltip.css";

import ScrollToTop from "./shared/components/scrollToTop/ScrollToTop";

import UpsideRoutes from "./routes/UpsideRoutes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/app">
        <ScrollToTop />

        <Suspense fallback={<p>Loading upside</p>}>
          <UpsideRoutes />
        </Suspense>
      </BrowserRouter>

      <ToastContainer />
    </QueryClientProvider>
  );
};
export default App;
