import { getServerErrors } from "../../helpers/util";

const ServerErrors = ({ errors }) => {
  return (
    <div
      className="flex mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 maxMd:mb-3"
      role="alert"
    >
      <ul className="text-red-700 list-disc p-4 list-outside">
        {getServerErrors(errors).map((error, index) => (
          <li key={index} className="ml-4">
            {error}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ServerErrors;
