import React, { useState } from "react";

import SignUp from "../../../../shared/auth/signup/SignUp";

const SellerSignUp = () => {
  const [signupUserData, setSignupUserData] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company_name: "",
    property_criterias: {},
  });

  return (
    <SignUp
      loginUrl="/sellers/login"
      setSignupUserData={setSignupUserData}
      signupUserData={signupUserData}
    />
  );
};

export default SellerSignUp;
