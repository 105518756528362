import React from "react";

const FormLabel = ({ className, title, optional, htmlFor }) => {
  return (
    <label className={className} htmlFor={htmlFor}>
      {title}{" "}
      {optional === true && <span className="text-coolGrey">(Optional)</span>}
    </label>
  );
};

export default FormLabel;
