import React from "react";

const OnboardingTitle = ({ title, subTitle }) => {
  return (
    <>
      <h1 className="md:text-3xl text-xl text-metallicBlue font-bold block">
        {title}
      </h1>
      <p className="text-coolGrey mt-2 text-base maxMd:text-sm">{subTitle}</p>
    </>
  );
};

export default OnboardingTitle;
