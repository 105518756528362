import React from "react";

import clsx from "clsx";

const Loader = ({ className = "", style = {} }) => {
  return (
    <div className={clsx(className)} style={style}>
      <span className="loader flex items-center"></span>
    </div>
  );
};

export default Loader;
