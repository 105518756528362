import React from "react";

import { ReactComponent as UserIcon } from "../../../../../../assets/images/agent-icons/user.svg";
import { ReactComponent as BriefCaseIcon } from "../../../../../../assets/images/agent-icons/briefcase.svg";
import { ReactComponent as RightArrowIcon } from "../../../../../../assets/images/agent-icons/right-arrow.svg";

const AgentOnboardingCard = ({ title, subTitle, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex justify-between items-center gap-7 maxMd:gap-3 mt-5 hover:bg-bubbles p-7 maxSm:p-2 rounded-md border border-transparent hover:border-celeste cursor-pointer transition shadow-agentsAuthCards group"
    >
      <div className="group-hover:bg-darkturquoise p-2 text-black border border-darkturquoise rounded-lg w-full max-w-[3.125rem] h-12 flex justify-center items-center transition">
        {title === "Single Agent" ? (
          <UserIcon className="agentRoleIcon" />
        ) : (
          <BriefCaseIcon className="agentRoleIcon" />
        )}
      </div>
      <div className="w-full">
        <h4 className="text-lg maxSm:text-sm text-metallicBlue font-bold">
          {title}
        </h4>
        <p className="text-coolGrey text-sm maxSm:text-xs">{subTitle}</p>
      </div>
      <div className="group-hover:block transition">
        <RightArrowIcon className="teamOwnerIcon" />
      </div>
    </div>
  );
};

export default AgentOnboardingCard;
