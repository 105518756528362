import React, { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getSignupUser } from "../../api/investor";

import Loader from "../../components/loader/Loader";
import LinkExpire from "./LinkExpire";
import SetPasswordForm from "./SetPasswordForm";

const SetPassword = () => {
  const [isPasswordSet, setIsPasswordSet] = useState(null);
  const [params] = useSearchParams();
  const uniqueHash = params.get("unique_hash");
  const navigate = useNavigate();

  useQuery(["get-signup-user"], () => getSignupUser(uniqueHash), {
    enabled: !!uniqueHash,
    onSuccess: (res) => {
      setIsPasswordSet(res?.data?.data?.is_password_set);
    },
    onError: (err) => {
      navigate("/");
    },
  });

  if (isPasswordSet === null) {
    return (
      <Loader className="h-screen justify-center items-center flex text-2xl loader-content absolute w-full z-10 left-0 bg-white top-0" />
    );
  }
  if (isPasswordSet) {
    return <LinkExpire />;
  }
  if (!isPasswordSet) {
    return <SetPasswordForm />;
  }
};

export default SetPassword;
