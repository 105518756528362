import React from "react";

import { ReactComponent as Viewon } from "../../../assets/images/eye.svg";
import { ReactComponent as Viewoff } from "../../../assets/images/eye-off.svg";

const TogglePasswordVisibilityButton = ({
  isPasswordShown,
  togglePasswordVisibility,
}) => {
  return (
    <button
      className="eyeicon-toggle absolute right-4 h-full"
      type="button"
      onClick={togglePasswordVisibility}
    >
      {isPasswordShown ? <Viewoff /> : <Viewon />}
    </button>
  );
};

export default TogglePasswordVisibilityButton;
