import React from "react";

import clsx from "clsx";

const FormError = ({ className, error }) => {
  if (!error) {
    return null;
  }

  return (
    <div className={clsx("text-red-600 text-xs font-medium mt-1", className)}>
      {error}
    </div>
  );
};

export default FormError;
