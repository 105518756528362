import { create } from "zustand";

export const defaultState = {
  isRedirect: false,
  url: null,
};

export const useRedirect = create((set) => ({
  redirect: { ...defaultState },
  setRedirect: (obj) =>
    set((state) => ({ ...state, redirect: { ...state.redirect, ...obj } })),
  resetRedirect: () =>
    set((state) => ({ ...state, redirect: { ...defaultState } })),
}));

//
