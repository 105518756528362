import React from 'react'
import ForgotPassword from '../../../../shared/auth/forgot-password/ForgotPassword'
import { forgotPassword } from '../../../../shared/api/agent'

const AgentForgotPassword = () => {
  return (
    <ForgotPassword
        onSubmit={ forgotPassword }
        loginUrl='/agents/login'
    />
  )
}

export default AgentForgotPassword