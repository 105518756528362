import React from "react";

import { Link } from "react-router-dom";
import clsx from "clsx";
import { object, string } from "yup";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import styles from "../../../assets/css/signup.module.css";

import logo from "../../../assets/images/Logo3.png";
import Loader from "../../components/loader/Loader";

const forgotPasswordSchema = object({
  email: string().email().required(),
});

const ForgotPassword = ({ onSubmit, loginUrl }) => {
  const { mutate, isLoading } = useMutation((contact) => onSubmit(contact), {
    onError: (error, variables, context) => {
      toast.error(error.response.data.message);
    },
    onSuccess: (res) => {
      toast.success(res.data.message);
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  if (isLoading) {
    return (
      <Loader className="h-screen justify-center items-center flex text-2xl loader-content" />
    );
  }

  return (
    <>
      <section
        className={clsx(
          styles.custom_container,
          styles.custom_card,
          "h-screen"
        )}
      >
        <Link to="/">
          <img src={logo} alt="logo-img" className="mt-4 mb-8 w-44" />
        </Link>

        <div className={styles.card}>
          <div>
            <div className={styles.cardtext}>
              <h2 className="title_lg mb-2 text-[1.688rem] leading-[2.375rem]">
                Forgot Password
              </h2>
            </div>
            <div>
              <form
                className={styles.inputRight}
                onSubmit={formik.handleSubmit}
              >
                <div className="maxMd:mb-3 mb-4">
                  <label className={styles.form_label}>Email</label>
                  <input
                    type="text"
                    name="email"
                    className={clsx(styles.form_control, "!pr-0", {
                      "border border-red-500": formik.errors.email,
                    })}
                    placeholder="Enter your email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </div>
                <button
                  type="submit"
                  className={clsx(
                    "btn btn-primary flex items-center justify-center maxMd:mt-2 mt-1 w-full py-6",
                    { disabled: isLoading }
                  )}
                >
                  <span className={clsx({ "mr-2": isLoading })}>
                    Forgot Password
                  </span>
                  {isLoading && <Loader />}
                </button>

                <p className="text-base mt-3 text-black">
                  Or {""}
                  <Link to={loginUrl} className="text-steelblue font-medium">
                    sign in here
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
