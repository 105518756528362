/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import OtpInput from "react-otp-input";
import clsx from "clsx";

import Style from "../../../../../../assets/css/agent/auth.module.css";

import { useOnBoarding } from "../../../../../../shared/provider/OnBoardingProvider";
import FormButton from "../../../../../../shared/components/agent-form-control/FormButton";

const AgentOnboardingStepThree = () => {
  const {
    formik,
    verifyOtpFn,
    sendOtpFn,
    isErrorVerifyOtp,
    isLoadingVerifyOtp,
  } = useOnBoarding();

  const [timeRemaining, setTimeRemaining] = useState(180);

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  const handleResendOTP = () => {
    sendOtpFn({
      email: formik.values.email,
      phone: formik.values.phone,
      name: formik.values.first_name,
    });
    setTimeRemaining(180);
    formik.setFieldValue("otp", "");
  };

  const onVerifyOtp = (e) => {
    e.preventDefault();

    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        return;
      }
      verifyOtpFn({
        otp: formik?.values?.otp,
        email: formik?.values.email,
      });
    });
  };

  const handleOtpEnterPress = (e) => {
    if (e.key === "Enter") {
      onVerifyOtp(e);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeRemaining]);

  // That we're using for Reset OTP input field when the component mounts to ensure a clean state
  useEffect(() => {
    formik.setFieldValue("otp", "");
  }, []);

  return (
    <div className="max-w-[30.625rem] mx-auto mt-40 text-center">
      <form onSubmit={onVerifyOtp}>
        <div>
          <h1 className="md:text-3xl text-xl text-metallicBlue font-bold block">
            Please enter Code to verify your account
          </h1>
          <p className="text-coolGrey mt-2 text-base maxMd:text-sm">
            A code has been sent to your email address{" "}
            <span className="text-metallicBlue">{formik?.values.email}</span>{" "}
            please verify it below.
          </p>
        </div>
        <div
          className={clsx("verification", {
            verificationError: formik?.errors?.otp || isErrorVerifyOtp,
          })}
        >
          <OtpInput
            value={formik?.values?.otp}
            onChange={(otp) => formik.setFieldValue("otp", otp)}
            numInputs={4}
            shouldAutoFocus={true}
            renderInput={(props) => (
              <input
                {...props}
                inputMode="numeric"
                maxLength={4}
                pattern="[0-9]*"
                type="number"
                onKeyPress={handleOtpEnterPress}
              />
            )}
          />
        </div>
        <div>
          <p className="text-coolGrey mt-2 text-base maxMd:text-sm">
            Time Remaining:{" "}
            <span className="text-metallicBlue">
              {" "}
              {String(minutes).padStart(2, "0")}:
              {String(seconds).padStart(2, "0")}
            </span>
          </p>
          <p className="text-coolGrey mt-2 text-base maxMd:text-sm">
            Didn't Received Code?{" "}
            <button
              className="text-metallicBlue"
              onClick={handleResendOTP}
              disabled={timeRemaining > 0}
            >
              RESEND CODE
            </button>
          </p>
        </div>
        <div className="max-w-[27rem] mx-auto w-full">
          <FormButton
            type="submit"
            className={clsx(Style.formButton, "flex justify-center")}
            title="Verify"
            loader={isLoadingVerifyOtp}
            disabled={isLoadingVerifyOtp}
          />
        </div>
      </form>
    </div>
  );
};

export default AgentOnboardingStepThree;
