import React, { createContext, useContext } from "react";

import { useQuery } from "@tanstack/react-query";

import { getUser } from "../api";
import { getToken } from "../helpers/util";

export const UserContext = createContext(null);
export const PublicUserContext = createContext(null);

export const UserProvider = ({ children, isPublic }) => {
  const {
    data: response,
    isLoading,

    refetch,
  } = useQuery(["getUser"], getUser, {
    enabled: isPublic ? !!getToken("Token") : true,
  });
  const value = { user: response?.data.data, isLoading, refetch };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  return useContext(UserContext);
};
