import React, { useState } from "react";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import clsx from "clsx";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";

import styles from "../../../assets/css/signup.module.css";

import { setPasswordSchema } from "./validation";
import { setPassword } from "../../api";
import { setToken } from "../../helpers/util";

import ServerErrors from "../../components/server-error";

import logo from "../../../assets/images/Logo3.png";
import viewon from "../../../assets/images/eye.svg";
import viewoff from "../../../assets/images/eye-off.svg";
import Loader from "../../components/loader/Loader";

const SetPasswordForm = () => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [params] = useSearchParams();
  const uniqueHash = params.get("unique_hash");

  // const [isPasswordSet, setIsPasswordSet] = useState(null);
  const { mutate, isLoading, error, isError } = useMutation(
    (data) => setPassword(data),
    {
      onSuccess: (res) => {
        setToken("Token", res?.data?.token);
        navigate("/");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
      unique_hash: uniqueHash,
    },
    validationSchema: setPasswordSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  if (isLoading) {
    return (
      <Loader className="h-screen justify-center items-center flex text-2xl loader-content" />
    );
  }

  return (
    <section
      className={clsx(styles.custom_container, styles.custom_card, "h-screen")}
    >
      <Link to="/">
        <img src={logo} alt="logo-img" className="mt-4 mb-8 w-44" />
      </Link>

      <div className={styles.card}>
        <div>
          <div className={styles.cardtext}>
            <h2 className="title_lg mb-2 text-[1.688rem] leading-[2.375rem]">
              Set Password
            </h2>
          </div>
          <div>
            <form className={styles.inputRight} onSubmit={formik.handleSubmit}>
              <div className={clsx(styles.view_icon, "maxMd:mb-3 mb-4")}>
                <label className={styles.form_label}>Password</label>
                <input
                  type={passwordShown ? "text" : "password"}
                  className={clsx(styles.form_control, "relative", {
                    "border border-red-500": formik.errors.password,
                  })}
                  placeholder="Password"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {passwordShown && (
                  <button
                    type="button"
                    className="!top-[22px] maxMd:!top-[32px]"
                    onClick={() => setPasswordShown(false)}
                  >
                    <img src={viewoff} alt="eye-icon" />
                  </button>
                )}
                {!passwordShown && (
                  <button
                    type="button"
                    className="!top-[22px] maxMd:!top-[32px]"
                    onClick={() => setPasswordShown(true)}
                  >
                    <img src={viewon} alt="view-icon" />
                  </button>
                )}
              </div>
              <div className={clsx(styles.view_icon, "maxMd:mb-3 mb-4")}>
                <label className={styles.form_label}>Confirm Password</label>
                <input
                  type={confirmPasswordShown ? "text" : "password"}
                  className={clsx(styles.form_control, "relative", {
                    "border border-red-500":
                      formik.errors.password_confirmation,
                  })}
                  placeholder="Confirm Password"
                  name="password_confirmation"
                  onChange={formik.handleChange}
                  value={formik.values.password_confirmation}
                />
                {confirmPasswordShown && (
                  <button
                    type="button"
                    className="!top-[22px] maxMd:!top-[32px]"
                    onClick={() => setConfirmPasswordShown(false)}
                  >
                    <img src={viewoff} alt="eye-icon" />
                  </button>
                )}
                {!confirmPasswordShown && (
                  <button
                    type="button"
                    className="!top-[22px] maxMd:!top-[32px]"
                    onClick={() => setConfirmPasswordShown(true)}
                  >
                    <img src={viewon} alt="view-icon" />
                  </button>
                )}
              </div>
              {isError && <ServerErrors errors={error} />}
              <button
                type="submit"
                className={clsx(
                  "btn btn-primary maxMd:mt-6 mt-1 w-full flex justify-center items-center gap-2 py-6",
                  { disabled: isLoading }
                )}
              >
                <span>Login</span>
                {isLoading && <Loader />}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SetPasswordForm;
