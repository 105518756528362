import { object, string } from "yup";

const commonFields = {
  first_name: string()
    .trim()
    // .matches(/^[a-zA-Z]+$/, "Enter valid first name.")
    .required("First name is required."),
  last_name: string()
    .trim()
    // .matches(/^[a-zA-Z]+$/, "Enter valid last name.")
    .required("Last name is required."),
  email: string()
    .trim()
    .matches(
      /^[a-zA-Z0-9+]+(?:\.[a-zA-Z0-9+]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      {
        message: "Invalid email format.",
      }
    )
    .required("Email is required."),
  phone: string()
    .required("Phone number is required.")
    .matches(/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, "Invalid phone number."),
  // phone: string()
  //   .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid phone format.")
  //   .required("Phone number is required."),
  password: string()
    .trim()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters long."),
};

export const sellerSignupSchema = object({ ...commonFields });

export const buyerSignupSchema = object({
  ...commonFields,
  password_confirmation: string()
    .trim()
    .required("Confirm password is required.")
    .min(8, "Confirm password must be at least 8 characters long.")
    .test(
      "password-match.",
      "Confirm Password do not match.",
      function (value) {
        return this.parent.password === value;
      }
    ),
});
