import React from "react";

import style from "../../assets/css/homeowner/auth.module.css";
import AuthStyle from "../../assets/css/agent/auth.module.css";

import { OnBoardingProvider } from "../provider/OnBoardingProvider";

import AgentSignupProgressBar from "../components/progress-bar/AgentSignupProgressBar";

import banner from "../../assets/images/agent-banner.png";

const withAgentSignupLayoutHOC = (ComposedComponent) => {
  const Component = (props) => {
    // const isDifferentHost = checkHostNameAndRedirect();

    // console.log(isDifferentHost);
    // if (isDifferentHost) {
    //   const targetHostname = process.env.REACT_APP_HOST_NAME;
    //   const url = new URL(window.location.href);
    //   const newUrl = `https://${targetHostname}${url.pathname}${url.search}${url.hash}`;
    //   window.location.href = newUrl;
    // }

    return (
      <section className="bg-white grid lg:grid-cols-2 maxLg:relative min-h-screen maxLg:p-6">
        <div className={style.banner}>
          <img
            src={banner}
            alt="banner"
            className="w-full h-screen object-cover"
          />
        </div>
        <OnBoardingProvider>
          <div className={AuthStyle.authContent}>
            <AgentSignupProgressBar />
            <ComposedComponent />
          </div>
        </OnBoardingProvider>
      </section>
    );
  };

  return <Component />;
};

export default withAgentSignupLayoutHOC;
