import React from "react";

import { ReactComponent as BrandLogo } from "../../../../../src/assets/images/logo4.svg";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { retrieveDomain } from "../../../../shared/api/agent";
import Loader from "../../../../shared/components/loader/Loader";

const PaymentSuccess = () => {
  const [params] = useSearchParams();
  const sessionId = params.get("session_id");
  const navigate = useNavigate();

  const { data: retriveDomainData, isLoading: isLoadingRetriveDomain } =
    useQuery(["get-domain"], () => retrieveDomain({ session_id: sessionId }), {
      enabled: !!sessionId,
    });

  const onClickLoginNow = () => {
    if (process.env.REACT_APP_DEVELOPMENT_ENVIRONMENT === "local") {
      navigate("agent/login?onBoarding");
    } else {
      window.location.replace(`${retriveDomainData.data.login_url}?onBoarding`);
    }
  };

  return (
    <>
      <div className="modal-card py-16 maxMd:py-8 px-16 maxMd:px-6 md:!max-w-xl shadow-congratModal md:max-h-[27.813rem] h-full maxMd:h-fit rounded-[1.25rem]">
        {isLoadingRetriveDomain ? (
          <Loader className="flex justify-center items-center h-full" />
        ) : (
          <>
            <div className="flex justify-center mb-6">
              <BrandLogo />
            </div>
            <div className="mt-4 text-center">
              <h1 className="text-[26px] maxMd:text-xl maxSm:!text-base text-metallicBlue font-bold block">
                Congratulations on completing your onboarding journey! 🎉
              </h1>
              <p className="text-coolGrey mt-2 text-base maxMd:text-sm">
                Your subdomain is{" "}
                <span className="text-metallicBlue font-medium">
                  {retriveDomainData.data.domain}
                </span>
              </p>
              <p className="text-coolGrey mt-2 text-base maxMd:text-sm">
                To get started, simply click the{" "}
                <span className="text-metallicBlue font-medium">
                  {" "}
                  'Login Now'
                </span>{" "}
                button below and begin your adventure.
              </p>
            </div>
            <div className="text-center">
              <button
                className="!bg-metallicBlue text-white w-full p-4 maxMd:p-2 rounded-md mt-8 hover:opacity-[0.9] transition max-w-[200px]"
                onClick={onClickLoginNow}
              >
                Login Now
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PaymentSuccess;
