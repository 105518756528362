import React from "react";

import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";

import styles from "../../../assets/css/signup.module.css";

import logo from "../../../assets/images/Logo3.png";

const LinkExpire = () => {
  const navigate = useNavigate();
  return (
    <>
      <section
        className={clsx(
          styles.custom_container,
          styles.custom_card,
          "h-screen"
        )}
      >
        <Link to="/">
          <img src={logo} alt="logo-img" className="mt-4 mb-8 w-44" />
        </Link>
        <div className={styles.card}>
          <div>
            <div className={styles.cardtext}>
              <h2 className="title_lg mb-2 text-center">Link Expired</h2>
            </div>
          </div>
          <button
            type="submit"
            className={clsx(
              "btn btn-primary maxMd:mt-6 mt-1 w-full flex justify-center items-center gap-2"
            )}
            onClick={() => navigate("/")}
          >
            <span> Go to Login</span>
          </button>
        </div>
      </section>
    </>
  );
};

export default LinkExpire;
