/* eslint-disable */
import React, { lazy, Suspense } from "react";

import Loader from "../components/loader/Loader";

const loadable = (
  importFunc,
  { fallback = null } = {
    fallback: (
      <Loader className="h-screen justify-center items-center flex text-2xl loader-content" />
    ),
  }
) => {
  const LazyComponent = lazy(importFunc);

  return (props) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
