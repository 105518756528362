import React, { useState } from "react";

import clsx from "clsx";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import style from "../../../assets/css/homeowner/auth.module.css";

import { homeownerResetPasswordSchema } from "../validation";
import { homeownerResetPassword } from "../../../shared/api/homehub";
import FormError from "../../../shared/components/form-control/FormError";
import ServerErrors from "../../../shared/components/server-error";
import Loader from "../../../shared/components/loader/Loader";
import TogglePasswordVisibilityButton from "../../../shared/components/password-visibility/TogglePasswordVisibilityButton";

import { ReactComponent as UserIcon } from "../../../assets/images/new-login/user.svg";
import { ReactComponent as LockIcon } from "../../../assets/images/new-login/lock.svg";

import AppLabel from "../../../shared/components/white-label/AppLabel";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { email, token } = useParams();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const {
    mutate: homeownerResetPwdMutate,
    isLoading: homeownerResetPwdIsLoading,
    error: homeownerResetPwdError,
    isError: homeownerResetPwdIsError,
  } = useMutation((contact) => homeownerResetPassword(contact), {
    onSuccess: (res) => {
      toast.success(res.data.message);
      navigate("/homehub/login");
    },
  });

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      email: email || "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: homeownerResetPasswordSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      values["token"] = token;
      homeownerResetPwdMutate(homeownerResetPasswordSchema.cast(values));
    },
  });

  return (
    <div
      className={clsx(style.auth, "max-w-none overflow-y-auto max-h-screen")}
    >
      <div className="max-w-[22.75rem] mx-auto">
        <AppLabel />

        <div
          className={
            "lg:h-[calc(100vh-88px)] maxLg:py-20 flex items-center flex-col justify-center"
          }
        >
          <h1 className="md:text-3xl text-xl text-yankeesblue font-bold">
            Reset Your Password?
          </h1>
          <form className="mt-6 pr-1 pb-7 w-full" onSubmit={handleSubmit}>
            <div className={style.inputGroup}>
              <div className={style.authInput_homehub}>
                <UserIcon className={style.authInputIcon_homehub} />
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  className={clsx(
                    { "is-error": errors.email },
                    "opacity-50 cursor-not-allowed"
                  )}
                  value={values.email}
                  disabled={true}
                />
              </div>
              <FormError error={errors.email} />
            </div>
            <div className={style.inputGroup}>
              <div className={style.authInput_homehub}>
                <LockIcon className={style.authInputIcon_homehub} />
                <input
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  className={errors.password && "is-error"}
                  value={values.password}
                />
                <TogglePasswordVisibilityButton
                  isPasswordShown={passwordShown}
                  togglePasswordVisibility={() =>
                    setPasswordShown(!passwordShown)
                  }
                />
              </div>
              <FormError error={errors.password} />
            </div>
            <div className={style.inputGroup}>
              <div className={style.authInput_homehub}>
                <LockIcon className={style.authInputIcon_homehub} />
                <input
                  type={confirmPasswordShown ? "text" : "password"}
                  name="password_confirmation"
                  placeholder="Password Confirmation"
                  onChange={handleChange}
                  className={errors.password_confirmation && "is-error"}
                  value={values.password_confirmation}
                />
                <TogglePasswordVisibilityButton
                  isPasswordShown={confirmPasswordShown}
                  togglePasswordVisibility={() =>
                    setConfirmPasswordShown(!confirmPasswordShown)
                  }
                />
              </div>
              <FormError error={errors.password_confirmation} />
            </div>
            {homeownerResetPwdIsError && (
              <ServerErrors errors={homeownerResetPwdError} />
            )}
            <button
              type="submit"
              className={"btn-auth_ho px-6"}
              disabled={homeownerResetPwdIsLoading}
            >
              {homeownerResetPwdIsLoading && (
                <Loader className="justify-center mr-3" />
              )}
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
