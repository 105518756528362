import api from "..";

export const login = (data) => api.post("admin/auth/login", data);

export const forgotPassword = (data) =>
  api.post("admin/auth/password/email", data);

export const resetPassword = (data) =>
  api.post("admin/auth/reset/password", data);

export const getStatastics = () => api.get("/admin/dashboard");

// export const logout = (data) => api.post("admin/auth/logout", data);

export const getInvestors = (search, page) =>
  api.get("investors", {
    params: {
      search,
      page,
    },
  });

export const getSellers = (search, page) =>
  api.get("admin/sellers", {
    params: {
      search,
      page,
    },
  });

export const getAgents = (search, page) =>
  api.get("admin/agent", {
    params: {
      search,
      page,
    },
  });

export const getAllInvestors = () =>
  api.get("investors", {
    params: {
      limit: "all",
    },
  });

export const getAllSellers = () =>
  api.get("admin/sellers", {
    params: {
      limit: "all",
    },
  });

export const getAssignAgents = async (search) => {
  const res = await api.get("admin/agent", {
    params: {
      limit: "all",
      search: search ?? "",
    },
  });
  return res;
};

export const getAllAgents = () =>
  api.get("admin/agent", {
    params: {
      limit: "all",
    },
  });

export const getProperties = (search, page, orderByField, orderBy) =>
  api.get(`admin/properties/listing`, {
    params: {
      search,
      page,
      order_by_field: orderByField,
      order_by: orderBy,
    },
  });
export const getPropertiesWithTheShowParams = (
  search,
  page,
  orderByField,
  orderBy
) =>
  api.get(`admin/all/properties/listing`, {
    params: {
      search,
      page,
      order_by_field: orderByField,
      order_by: orderBy,
    },
  });

export const getAllProperties = (search, page, orderByField, orderBy, tabKey) =>
  api.get(`all/properties`, {
    params: {
      search,
      page,
      order_by_field: orderByField,
      order_by: orderBy,
      [tabKey]: true,
    },
  });

export const getSellerProperties = (
  coordinates = {},
  sellerId,
  timeFilter,
  dateFilter = {}
) =>
  api.get("/admin/properties/listing", {
    params: {
      ...coordinates,
      seller_id: sellerId,
      limit: "all",
      type: timeFilter,
      ...dateFilter,
    },
  });
export const getagentsroperties = (
  coordinates = {},
  agentId,
  timeFilter,
  dateFilter = {}
) =>
  api.get("/admin/properties/listing", {
    params: {
      ...coordinates,
      agent_id: agentId,
      limit: "all",
      type: timeFilter,
      ...dateFilter,
    },
  });

export const getInvestorProperties = (
  coordinates = {},
  investorId,
  timeFilter,
  dateFilter = {},
  sold_at
) =>
  api.get("admin/properties/listing", {
    params: {
      ...coordinates,
      investor_id: investorId,
      limit: "all",
      type: timeFilter,
      ...dateFilter,
      sold_at: sold_at,
    },
  });

export const getOfferProperties = (
  coordinates = {},
  timeFilter,
  dateFilter = {}
) =>
  api.get("admin/properties/listing", {
    params: {
      ...coordinates,
      offer_id: "",
      type: timeFilter,
      ...dateFilter,
      limit: Object.keys(coordinates).length !== 0 ? "" : "all",
    },
  });

export const getAdminOffer = (offer) => api.get(`/admin/offers/${offer}`);

export const getAdminOfferHistory = (offer) =>
  api.get(`/admin/${offer}/offer-history`);

export const getHomeHubOfferHistory = (offer) =>
  api.get(`/${offer}/offer-history`);

export const getInvestorStats = (
  coordinates = {},
  investorId,
  timeFilter,
  dateFilter = {}
) =>
  api.get("/admin/investors/stats", {
    params: {
      ...coordinates,
      investor_id: investorId,
      type: timeFilter,
      ...dateFilter,
    },
  });

export const getSellerStats = (
  coordinates = {},
  userId,
  timeFilter,
  dateFilter = {},
  modelType
) =>
  api.get("/admin/agent-seller/stats", {
    params: {
      ...coordinates,
      id: userId,
      type: timeFilter,
      model_type: modelType,
      ...dateFilter,
    },
  });

export const getOfferStats = (coordinates = {}, timeFilter, dateFilter = {}) =>
  api.get("/admin/offers/stats", {
    params: {
      ...coordinates,
      type: timeFilter,
      ...dateFilter,
    },
  });

export const getInvestorListing = (
  coordinates = {},
  investorId,
  timeFilter,
  dateFilter = {}
) =>
  api.get("/admin/investors/dashboard/listing", {
    params: {
      ...coordinates,
      investor_id: investorId,
      type: timeFilter,
      ...dateFilter,
    },
  });

export const getSellerListing = (
  coordinates = {},
  userId,
  timeFilter,
  dateFilter = {},
  modelType
) =>
  api.get("/admin/agents-sellers/dashboard/listing", {
    params: {
      ...coordinates,
      id: userId,
      type: timeFilter,
      model_type: modelType,
      ...dateFilter,
    },
  });

export const getOfferListing = (
  coordinates = {},
  timeFilter,
  dateFilter = {}
) =>
  api.get("/admin/offers/dashboard/listing", {
    params: {
      ...coordinates,
      type: timeFilter,
      ...dateFilter,
    },
  });

export const addWholesaler = (data) => api.post("admin/sellers", data);

export const updateWholesaler = (id, data) =>
  api.put(`admin/sellers/${id}`, data);

export const getWholesaler = (id) => api.get(`admin/sellers/${id}`);

export const deleteWholesaler = (id) => api.delete(`admin/sellers/${id}`);

export const addBuyer = (data) => api.post("investors", data);

export const getBuyer = (id) => api.get(`investors/${id}`);

export const updateBuyer = (id, data) => api.put(`investors/${id}`, data);

export const deleteBuyer = (id) => api.delete(`investors/${id}`);

export const updateAgent = (id, data) => api.put(`admin/agent/${id}`, data);

export const getAgent = (id) => api.get(`admin/agent/${id}`);

export const deleteAgent = (id) => api.delete(`agents/${id}`);

export const getAdminProperties = (
  search,
  page,
  orderByField,
  orderBy,
  isReviewTab,
  isRejectedProperties
) =>
  api.get(`properties`, {
    params: {
      search,
      page,
      order_by_field: orderByField,
      order_by: orderBy,
      ...(isReviewTab === true ? { consent_form: true } : {}),
      ...(isRejectedProperties === true ? { rejected: true } : {}),
    },
  });

export const changePassword = (agent, data) =>
  api.post(`/admin/change-password/${agent}`, data);

export const approveRejectProperty = (data) =>
  api.post(`/property-status`, data);

export const downloadPropertyConsentForm = (uniqueHash) =>
  api.get(`/admin/download-consent-form/${uniqueHash}`);

export const approveRejectLead = (lead, data) =>
  api.put(`admin/leads/${lead}`, data);

export const updateProfile = (data) => api.post("/profile", data);

export const postAssignAgent = (lead, data) => api.put(`/leads/${lead}`, data);

export const getHomeOwnerProperties = (address, page) =>
  api.get("/home-owner-requests", {
    params: {
      address,
      page,
    },
  });

export const getHomeOwnerPropertyDetail = (propertyDetailId) =>
  api.get(`/property-detail/${propertyDetailId}/home-owner-requests`);

export const getHomeOwnerPropertyOfferDetail = (contactId) =>
  api.get(`home-owner-requests/${contactId}`);

export const getContactPropertyDetail = (propertyDetailId) =>
  api.get(`/home-owner-property-detail/${propertyDetailId}`);

// export const getHomeOwnerUsers = (HORequestId) =>
//   api.get(`home-owner-users/${HORequestId}`);

export const addHomeHownerUser = (homeOnwerRequestId) =>
  api.post(`home-owner-user/${homeOnwerRequestId}`);

export const getHomehubUserListing = (search, page) =>
  api.get("homehub-users", {
    params: {
      search,
      page,
    },
  });

export const getadminAllContact = (search, page) =>
  api.get("/admin/contacts", {
    params: {
      search,
      page,
    },
  });

export const getAdminTeams = (page, search = "") => {
  return api.get("admin/teams", {
    params: {
      page,
      search,
    },
  });
};
