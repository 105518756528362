import React from "react";

import ProgressBar from "@ramonak/react-progress-bar";

import { ReactComponent as BackButtonIcon } from "../../../../../assets/images/agent-icons/left-arrow.svg";

const InvestorOnboardingProgressBar = ({ stepNumber, onBackOnBoarding }) => {
  return (
    <div className="sticky top-0 bg-white pt-9 z-[1113]">
      <div className="flex justify-between items-center">
        {stepNumber !== 1 && (
          <button onClick={onBackOnBoarding}>
            <BackButtonIcon />
          </button>
        )}
        <div className="flex-grow text-center">
          <h4 className="text-xs text-[#394E77] font-inter-medium">
            Step 0{stepNumber} of 02 Complete
          </h4>
        </div>
        <div className="bg-darkturquoise text-xs px-2 py-1 text-white rounded-lg">
          0{stepNumber}/02
        </div>
      </div>
      <ProgressBar
        className="mt-4"
        width="100%"
        height={"6px"}
        bgColor="#1AC8DB"
        isLabelVisible={false}
        completed={stepNumber === 1 ? 50 : 100}
      />
    </div>
  );
};

export default InvestorOnboardingProgressBar;
