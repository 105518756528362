import React from "react";
import {
  checkHostNameAndRedirect,
  isUpsideAppTenant,
} from "../shared/helpers/util";

const checkURLCondition = (WrappedComponent) => {
  const Component = (props) => {
    const url = window.location.href;
    const isDifferentHost = checkHostNameAndRedirect();
    const hasKeywords = (keywords) =>
      keywords.some((keyword) => url.includes(keyword));

    // eslint-disable-next-line no-useless-escape

    if (
      !isUpsideAppTenant(window.location.href) &&
      isDifferentHost &&
      (url.includes("login") || url.includes("signup"))
    ) {
      if (
        (hasKeywords(["agents"]) && url.includes("login")) ||
        (hasKeywords(["homehub"]) &&
          (url.includes("signup") || url.includes("login")))
      ) {
        return <WrappedComponent {...props} />;
      } else {
        const targetHostname = process.env.REACT_APP_HOST_NAME;
        const currenturl = new URL(window.location.href);
        const newUrl = `https://${targetHostname}${currenturl.pathname}${currenturl.search}${currenturl.hash}`;
        window.location.href = newUrl;
        // return <Navigate to="/" />;
      }
    } else if (
      isUpsideAppTenant(window.location.href) &&
      (url.includes("login") || url.includes("signup"))
    ) {
      if (
        (hasKeywords(["agents"]) && url.includes("login")) ||
        (hasKeywords(["homehub"]) &&
          (url.includes("signup") || url.includes("login")))
      ) {
        const newUrl = `https://upsideapp.radicalloop.com/app`;
        window.location.href = newUrl;
      } else {
        return <WrappedComponent {...props} />;
      }
    } else {
      return <WrappedComponent {...props} />;
    }
  };
  return <Component />;
};

export default checkURLCondition;
