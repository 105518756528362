import React from "react";

import { login } from "../../../../shared/api/seller";

import Login from "../../../../shared/auth/login/Login";

const SellerLogin = () => {
  return (
    <Login onSubmit={login} forgotPasswordUrl="/sellers/forgot-password" />
  );
};

export default SellerLogin;
