import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import style from "../../../assets/css/homeowner/auth.module.css";

import { homeownerLoginSchema } from "../validation";
import { setToken } from "../../../shared/helpers/util";
import { homeownerLoginApi } from "../../../shared/api/homehub";
import FormError from "../../../shared/components/form-control/FormError";
import ServerErrors from "../../../shared/components/server-error";
import Loader from "../../../shared/components/loader/Loader";
import TogglePasswordVisibilityButton from "../../../shared/components/password-visibility/TogglePasswordVisibilityButton";

import { ReactComponent as EmailIcon } from "../../../assets/images/new-login/email.svg";
import { ReactComponent as LockIcon } from "../../../assets/images/new-login/lock.svg";

import AppLabel from "../../../shared/components/white-label/AppLabel";

const Login = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [passwordShown, setPasswordShown] = useState(false);

  const {
    mutate: homeownerLoginMutate,
    isLoading: homeownerLoginIsLoading,
    error: homeownerLoginError,
    isError: homeownerLoginIsError,
  } = useMutation((contact) => homeownerLoginApi(contact), {
    onSuccess: (res) => {
      setToken("Token", res?.data?.token);

      navigate(
        !res?.data?.property_id
          ? `/properties`
          : `/property/${res?.data?.property_id}/details`
      );
    },
  });

  const { handleSubmit, handleChange, errors, values } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: homeownerLoginSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      homeownerLoginMutate(values);
    },
  });

  useEffect(() => {
    if (params.has("is_verified")) {
      toast("Your email verified successfully", { type: "success" });
    }
  }, [params]);

  return (
    <div
      className={clsx(style.auth, "max-w-none overflow-y-auto max-h-screen")}
    >
      <div className="max-w-[22.75rem] mx-auto">
        <AppLabel />
        <div
          className={
            "lg:h-[calc(100vh-88px)] maxLg:py-20 flex items-center flex-col justify-center"
          }
        >
          <h1 className="md:text-3xl text-xl text-yankeesblue font-bold">
            Welcome Back!
          </h1>
          <p className="text-[#525252] mt-2 maxMd:text-sm">
            Log in to your home dashboard here.
          </p>
          <form className="mt-6 pr-1 pb-7 w-full" onSubmit={handleSubmit}>
            <div className={style.inputGroup}>
              <div className={style.authInput_homehub}>
                <EmailIcon className={style.authInputIcon_homehub} />
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  className={errors.email && "is-error"}
                  value={values.email}
                />
              </div>
              <FormError error={errors.email} />
            </div>
            <div className={style.inputGroup}>
              <div className={style.authInput_homehub}>
                <LockIcon
                  className={clsx(style.authInputIcon_homehub, "z-[1]")}
                />
                <input
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  className={clsx("relative !pr-12", {
                    "is-error": errors.password,
                  })}
                  value={values.password}
                />
                <TogglePasswordVisibilityButton
                  isPasswordShown={passwordShown}
                  togglePasswordVisibility={() =>
                    setPasswordShown(!passwordShown)
                  }
                />
              </div>
              <FormError error={errors.password} />
            </div>
            {homeownerLoginIsError && (
              <ServerErrors errors={homeownerLoginError} />
            )}
            <div className={style.inputGroup}>
              <Link
                to={"/homehub/forgot-password"}
                className="text-bondiblue text-sm underline mx-auto my-1"
              >
                Forgot Password?
              </Link>
            </div>
            <div className={style.inputGroup}>
              <Link
                to={"/homehub/signup"}
                className="text-bondiblue text-sm underline mx-auto my-1"
              >
                Don't have an account?{" "}
                <strong className="font-inter-semiBold">Sign up</strong>
              </Link>
            </div>
            <button
              type="submit"
              className={"btn-auth_ho px-[38px] maxMd:mt-8"}
              disabled={homeownerLoginIsLoading}
            >
              {homeownerLoginIsLoading && (
                <Loader className="justify-center mr-3" />
              )}
              Log in
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
