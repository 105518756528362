import React from "react";
import ResetPassword from "../../../../shared/auth/reset-password/ResetPassword";
import { resetPassword } from "../../../../shared/api/investor";
import { useParams } from "react-router-dom";

const InvestorResetPassword = () => {
  const { email } = useParams();

  return (
    <ResetPassword
      onSubmit={resetPassword}
      loginUrl="/buyers/login"
      email={email}
    />
  );
};

export default InvestorResetPassword;
