import { useUser } from "../shared/provider/UserProvider";

import {
  isUserAdmin,
  isUserAgent,
  isUserHomeowner,
  isUserInvestor,
  isUserSeller,
} from "../shared/helpers/util";

import loadable from "../shared/helpers/loadable";
import Loader from "../shared/components/loader/Loader";

const Seller = loadable(() => import("../modules/sellers/home"));
const Investor = loadable(() => import("../modules/investors/home"));
const Admin = loadable(() => import("../modules/admins/home"));
const Agent = loadable(() => import("../modules/agents/home"));
const Homeowner = loadable(() => import("../home-owner/layout/MainLayout"));

const AuthenticatedApp = () => {
  // get user from Context
  const { isLoading: userIsLoading, user } = useUser();

  if (userIsLoading) {
    return (
      <Loader className="h-screen justify-center items-center flex text-2xl loader-content" />
    );
  }

  return (
    <>
      {isUserSeller(user?.role) && <Seller />}
      {isUserInvestor(user?.role) && <Investor />}
      {isUserAdmin(user?.role) && <Admin />}
      {isUserAgent(user?.role) && <Agent />}
      {isUserHomeowner(user?.role) && <Homeowner />}
    </>
  );
};

export default AuthenticatedApp;
