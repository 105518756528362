import React, { useState } from "react";

import { useFormik } from "formik";
import clsx from "clsx";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MaskedInput from "react-text-mask";

import style from "../../../assets/css/homeowner/auth.module.css";

import ServerErrors from "../../../shared/components/server-error";
import { formatPhoneNumber, phoneMask } from "../../../shared/api/data";
import Loader from "../../../shared/components/loader/Loader";
import FormError from "../../../shared/components/form-control/FormError";
import {
  contactSignupApi,
  homeownerDataFetch,
  homeownerSignupApi,
} from "../../../shared/api/homehub";
import { homeownerSignupSchema } from "../validation";
import TogglePasswordVisibilityButton from "../../../shared/components/password-visibility/TogglePasswordVisibilityButton";

import { ReactComponent as UserIcon } from "../../../assets/images/new-login/user.svg";
import { ReactComponent as EmailIcon } from "../../../assets/images/new-login/email.svg";
import { ReactComponent as CallIcon } from "../../../assets/images/new-login/call.svg";
import { ReactComponent as LocationIcon } from "../../../assets/images/new-login/location.svg";
import { ReactComponent as LockIcon } from "../../../assets/images/new-login/lock.svg";

import ReApiDropDown from "../../../shared/components/re-api-dropdown/ReApiDropDown";
import { omit } from "lodash";
import { getContactAddress } from "../../../shared/api/agent";
import AppLabel from "../../../shared/components/white-label/AppLabel";
import { unmaskPhone } from "../../../shared/helpers/util";

const SignUp = () => {
  const navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState(false);
  const [searchAddressKeyword, setSearchAddressKeyword] = useState("");

  const location = useLocation();

  const uniqueHash = new URLSearchParams(location.search).get("unique_hash");

  const {
    mutate: homeownerSignupMutate,
    isLoading: homeownerSignupisLoading,
    error: homeownerSignupError,
    isError: homeownerSignupIsError,
  } = useMutation((data) => homeownerSignupApi(data), {
    onSuccess: () => {
      handleReset();
      toast.success("Verification link sent to your email!");
      navigate("/homehub/login");
    },
  });

  const {
    mutate: contactSignupMutate,
    isLoading: contactSignupisLoading,
    error: contactSignupError,
    isError: contactSignupIsError,
  } = useMutation((data) => contactSignupApi(data), {
    onSuccess: () => {
      handleReset();
      toast.success("Verification link sent to your email!");
      navigate("/homehub/login");
    },
  });

  const { data: contactData, isLoading: isLoadingContactData } = useQuery(
    ["get-contact-data", uniqueHash],
    () => homeownerDataFetch(uniqueHash),
    {
      enabled: !!uniqueHash,
      onSuccess: (res) => {
        setSearchAddressKeyword(
          res?.meta?.home_owner_property?.property_detail?.address
        );
        setFieldValue("first_name", res?.data.first_name);
        setFieldValue("last_name", res?.data.last_name);
        setFieldValue("email", res?.data.email);
        setFieldValue("phone", formatPhoneNumber(res?.data.phone));
        setFieldValue("unique_hash", res?.data.unique_hash);
        setSearchAddressKeyword(
          res?.meta?.home_owner_property?.property_detail?.address
        );
      },
      select: (res) => {
        return res.data;
      },
    }
  );

  const { data: addressData, isLoading: isLoadingAddressData } = useQuery(
    ["get-address"],
    () => getContactAddress(searchAddressKeyword, ["A"]),

    {
      enabled: searchAddressKeyword?.length >= 3,
    }
  );

  const {
    handleSubmit,
    handleReset,
    handleChange,
    errors,
    values,
    setFieldValue,
  } = useFormik({
    initialValues: {
      address_data: {},
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      unique_hash: "",
    },
    validationSchema: homeownerSignupSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formattedValues = {
        ...values,
        phone: unmaskPhone(values.phone),
      };

      uniqueHash
        ? contactSignupMutate(homeownerSignupSchema.cast(formattedValues))
        : homeownerSignupMutate(
            homeownerSignupSchema.cast(omit(formattedValues, ["unique_hash"]))
          );
    },
  });

  return (
    <div
      className={clsx(style.auth, "max-w-none overflow-y-auto max-h-screen")}
    >
      <div className="max-w-[22.75rem] mx-auto">
        <AppLabel />

        <div className="h-full pt-6">
          <h1 className="md:text-3xl text-xl text-yankeesblue inter-bold">
            Create Account
          </h1>
          <p className="text-[#525252] mt-2 maxMd:text-sm">
            Register your details here
          </p>

          {uniqueHash && isLoadingContactData ? (
            <Loader className="flex justify-center items-center h-full mt-4" />
          ) : uniqueHash &&
            searchAddressKeyword?.length >= 3 &&
            isLoadingAddressData ? (
            <Loader className="flex justify-center items-center h-full mt-4" />
          ) : (
            <form onSubmit={handleSubmit} className="mt-6 pr-1 h-full pb-7">
              <div className={style.inputGroup}>
                <div className={style.authInput_homehub}>
                  <UserIcon className={style.authInputIcon_homehub} />
                  <input
                    name="first_name"
                    placeholder="First Name"
                    className={errors.first_name && "is-error"}
                    onChange={handleChange}
                    value={values.first_name}
                  />
                </div>
                <FormError error={errors.first_name} />
              </div>
              <div className={style.inputGroup}>
                <div className={style.authInput_homehub}>
                  <UserIcon className={style.authInputIcon_homehub} />
                  <input
                    name="last_name"
                    placeholder="Last Name"
                    onChange={handleChange}
                    className={errors.last_name && "is-error"}
                    value={values.last_name}
                  />
                </div>
                <FormError error={errors.last_name} />
              </div>
              <div className={style.inputGroup}>
                <div className={style.authInput_homehub}>
                  <EmailIcon className={style.authInputIcon_homehub} />
                  <input
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    value={values.email}
                    disabled={!!uniqueHash}
                    className={clsx(
                      { "is-error": errors.email },
                      { "opacity-50 cursor-not-allowed": !!uniqueHash }
                    )}
                  />
                </div>
                <FormError error={errors.email} />
              </div>
              <div className={style.inputGroup}>
                <div className={style.authInput_homehub}>
                  <CallIcon className={style.authInputIcon_homehub} />
                  <MaskedInput
                    mask={phoneMask}
                    className={errors.phone && "is-error"}
                    placeholder="Phone"
                    guide={false}
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                  />
                </div>
                <FormError error={errors.phone} />
              </div>
              <div className={style.inputGroup}>
                <div className={style.authInput_homehub}>
                  <LocationIcon
                    className={clsx("z-[99]", style.authInputIcon_homehub)}
                  />

                  <ReApiDropDown
                    className={
                      "homeowner-signup-address w-full col-span-2 border border-cloud rounded-[10px] "
                    }
                    isError={errors?.address_data}
                    placeholderName={"Street address"}
                    setFieldValue={setFieldValue}
                    classNamePrefix={"homeowner-reapi"}
                    defaultvalue={addressData?.data[0]}
                    module="homeowner_signup"
                    disabled={
                      contactData?.meta?.home_owner_property?.property_detail
                        ?.address !== undefined
                    }
                  />
                </div>
                <FormError error={errors?.address_data} />
              </div>
              <div className={style.inputGroup}>
                <div className={style.authInput_homehub}>
                  <LockIcon
                    className={clsx(style.authInputIcon_homehub, "z-[1]")}
                  />
                  <input
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    placeholder="Create Password"
                    onChange={handleChange}
                    value={values.password}
                    className={clsx("relative !pr-12", {
                      "is-error": errors.password,
                    })}
                  />
                  <TogglePasswordVisibilityButton
                    isPasswordShown={passwordShown}
                    togglePasswordVisibility={() =>
                      setPasswordShown(!passwordShown)
                    }
                  />
                </div>
                <FormError error={errors.password} />
              </div>
              <div className={style.inputGroup}>
                <Link
                  to={"/homehub/login"}
                  className="text-bondiblue text-sm underline mx-auto my-1"
                >
                  Already have an account?{" "}
                  <strong className="font-inter-semiBold">Login</strong>
                </Link>
              </div>
              {(homeownerSignupIsError || contactSignupIsError) && (
                <ServerErrors
                  errors={homeownerSignupError || contactSignupError}
                />
              )}
              <button
                type="submit"
                className={"btn-auth_ho px-4"}
                disabled={homeownerSignupisLoading || contactSignupisLoading}
              >
                {(homeownerSignupisLoading || contactSignupisLoading) && (
                  <Loader className="justify-center mr-2" />
                )}
                Create Account
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
