import { create } from "zustand";

export const defaultState = {
  first_name: null,
  last_name: null,
  email: null,
  phone: null,
  company_name: null,
};

export const useInvestorSignUpData = create((set) => ({
  investorSignUpData: { ...defaultState },
  setInvestorSignUpData: (obj) =>
    set((state) => ({
      ...state,
      investorSignUpData: { ...state.investorSignUpData, ...obj },
    })),
  resetInvestorSignUpData: () =>
    set((state) => ({ ...state, investorSignUpData: { ...defaultState } })),
}));
