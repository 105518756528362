import banner from "../../assets/images/new-login/login-banner.png";
import style from "../../assets/css/homeowner/auth.module.css";

const withAuthPagesLayoutHOC = (ComposedComponent) => {
  return (
    <section className="bg-white grid lg:grid-cols-2 maxLg:relative min-h-screen maxLg:p-6">
      <ComposedComponent />
      <div className={style.banner}>
        <img
        src={banner}
        alt="banner"
        className="w-full h-screen object-cover"
        />
      </div>
    </section>
  );
}

export default withAuthPagesLayoutHOC;