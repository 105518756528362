import React from 'react'
import Login from '../../../../shared/auth/login/Login'
import { login } from '../../../../shared/api/admin'

const AdminLogin = () => {
  return (
    <Login
      onSubmit={login}
      forgotPasswordUrl = '/admin/forgot-password'
      title="Admin Login"
    />
  )
}

export default AdminLogin