import React from "react";

import OnboardingTitle from "../../../../../../shared/components/agent-auth-title";

import OnboardingStepOneForm from "./OnboardingStepOneForm";

const InvestorOnboardingStepOne = ({
  setStep,
  setSignupUserData,
  signupUserData,
}) => {
  return (
    <>
      <div className="max-w-[26.625rem] mx-auto h-full md:py-10 maxMd:py-6">
        <div className="mb-10">
          <OnboardingTitle
            title="Welcome to Upside!"
            subTitle="Let’s personalize your account. Please enter your information below."
          />
        </div>
        <OnboardingStepOneForm
          setStep={setStep}
          setSignupUserData={setSignupUserData}
          signupUserData={signupUserData}
        />
      </div>
    </>
  );
};

export default InvestorOnboardingStepOne;
