import React from "react";

import { useParams } from "react-router-dom";

import { resetPassword } from "../../../../shared/api/seller";

import ResetPassword from "../../../../shared/auth/reset-password/ResetPassword";

const SellerResetPassword = () => {
  const { email } = useParams();

  return (
    <ResetPassword
      onSubmit={resetPassword}
      loginUrl="/sellers/login"
      email={email}
    />
  );
};

export default SellerResetPassword;
