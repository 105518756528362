import { Navigate } from "react-router-dom";

import { getToken } from "./util";

const withAuthentication = (ComposedComponent) => {
  const Component = (props) => {
    if (getToken("Token")) {
      return <ComposedComponent {...props} />;
    }
    return <Navigate to={"/buyers/login"} />;
  };
  return <Component />;
};

export default withAuthentication;
