import { range } from "lodash";

const years = range(new Date().getFullYear(), 1900);

const SelectYearBuilt = ({ onChange, name, className, value, defaultOption="Select" }) => {

    return (
        <select name={name} className={className} onChange={onChange} value={value || ''} >
            <option value="">{defaultOption}</option>
            {
                years.map((year) => <option key={`${name}_yr_${year}`} value={year}>{year}</option>)
            }
        </select>
    );
}

export default SelectYearBuilt;