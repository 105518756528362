import React from "react";

import { useOnBoarding } from "../../../../shared/provider/OnBoardingProvider";

import AgentOnboardingStepOne from "./steps/AgentOnboardingStepOne";
import AgentOnboardingStepTwo from "./steps/AgentOnboardingStepTwo";
import AgentOnboardingStepThree from "./steps/AgentOnboardingStepThree";
import AgentOnboardingStepFour from "./steps/AgentOnboardingStepFour";
import AgentOnboardingStepFive from "./steps/AgentOnboardingStepFive";

const AgentSignup = () => {
  const { currentStep } = useOnBoarding();

  const renderStep = (step) => {
    switch (step) {
      case 1:
        return <AgentOnboardingStepOne />;
      case 2:
        return <AgentOnboardingStepTwo />;
      case 3:
        return <AgentOnboardingStepThree />;
      case 4:
        return <AgentOnboardingStepFour />;
      case 5:
        return <AgentOnboardingStepFive />;
      default:
        return null;
    }
  };

  return <div>{renderStep(currentStep)}</div>;
};

export default AgentSignup;
