import React, { useState } from "react";

import clsx from "clsx";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";

import Loader from "../../../shared/components/loader/Loader";
import ServerErrors from "../../../shared/components/server-error";
import { setToken } from "../../../shared/helpers/util";
import { homeownerSetPassword } from "../../../shared/api/homehub";
import { homeownerSetPasswordSchema } from "../validation";
import TogglePasswordVisibilityButton from "../../../shared/components/password-visibility/TogglePasswordVisibilityButton";

const CheckPasswordModal = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const uniqueHash = params.get("unique_hash");

  const {
    mutate: homeownerSetPasswordMutate,
    isLoading: homeownerSetPwdIsLoading,
    error: homeownerSetPwdError,
    isError: homeownerSetPwdIsError,
  } = useMutation((contact) => homeownerSetPassword(contact), {
    onSuccess: (res) => {
      toast.success("Password set successfully");
      setToken("Token", res?.data?.token);
      navigate(`/property/${res?.data?.property_id}/details`);
    },
  });

  const { handleSubmit, errors, handleChange, values } = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: homeownerSetPasswordSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      values["unique_hash"] = uniqueHash;
      homeownerSetPasswordMutate(homeownerSetPasswordSchema.cast(values));
    },
  });

  return (
    <>
      <div className="overlay w-full">
        <form className="modal-card" onSubmit={handleSubmit}>
          <h1 className="text-4xl font-inter-semiBold mb-8 text-davygrey">
            Create Password
          </h1>
          <div className="form-group mb-4">
            <label
              htmlFor="password"
              className="font-semibold mb-2 text-davygrey text-sm"
            >
              Enter Password
            </label>
            <div className="relative">
              <input
                type={passwordShown ? "text" : "password"}
                name="password"
                onChange={handleChange}
                className={clsx("form-control bg-white w-full pr-10", {
                  "is-error": errors.password,
                })}
                value={values.password}
              />
              <TogglePasswordVisibilityButton
                isPasswordShown={passwordShown}
                togglePasswordVisibility={() =>
                  setPasswordShown(!passwordShown)
                }
              />
            </div>
          </div>
          <div className="form-group mb-8">
            <label
              htmlFor="password_confirmation"
              className="font-semibold mb-2 text-davygrey text-sm"
            >
              Enter Confirm Password
            </label>
            <div className="relative">
              <input
                type={confirmPasswordShown ? "text" : "password"}
                name="password_confirmation"
                onChange={handleChange}
                className={clsx(
                  {
                    "is-error form-control bg-white":
                      errors.password_confirmation,
                  },
                  "form-control bg-white w-full pr-10"
                )}
                value={values.password_confirmation}
              />
              <TogglePasswordVisibilityButton
                isPasswordShown={confirmPasswordShown}
                togglePasswordVisibility={() =>
                  setConfirmPasswordShown(!confirmPasswordShown)
                }
              />
            </div>
          </div>
          {homeownerSetPwdIsError && (
            <ServerErrors errors={homeownerSetPwdError} />
          )}
          <button
            type="submit"
            className={clsx(
              "dark-button w-full flex items-center justify-center",
              {
                disabled: homeownerSetPwdIsLoading,
              }
            )}
          >
            {homeownerSetPwdIsLoading && (
              <Loader className="justify-center items-center flex mr-3" />
            )}
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default CheckPasswordModal;
