import React from 'react'
import Login from '../../../../shared/auth/login/Login'
import { login } from '../../../../shared/api/agent'

const AgentLogin = () => {
  return (
    <Login
      onSubmit={login}
      forgotPasswordUrl = '/agents/forgot-password'
      title="Agent Login"
    />
  )
}

export default AgentLogin