import React from "react";

import ScrollToTop from "../../../../../../shared/components/scrollToTop/ScrollToTop";
import OnboardingTitle from "../../../../../../shared/components/agent-auth-title";
import OnboardingStepTwoForm from "./OnboardingStepTwoForm";

const InvestorOnboardingStepTwo = ({ setStep, signupUserData }) => {
  return (
    <>
      <ScrollToTop className={"onboarding_inner"} />
      <div className="max-w-[26.625rem] mx-auto h-full md:py-10 maxMd:py-6">
        <div className="mb-10">
          <OnboardingTitle
            title="What type of properties are you looking for?"
            subTitle="We will send you properties that match your buy box."
          />
        </div>
        <div>
          <OnboardingStepTwoForm
            setStep={setStep}
            signupUserData={signupUserData}
          />
        </div>
      </div>
    </>
  );
};

export default InvestorOnboardingStepTwo;
