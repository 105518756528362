import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ className = "main" }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (document.querySelector(`.${className}`)) {
        document.querySelector(`.${className}`).scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
};

export default ScrollToTop;
