import { Navigate } from "react-router-dom";

import { getToken } from "./util";

const withoutAuthenticationHO = (ComposedComponent) => {
  const Component = (props) => {
    if (getToken("Token")) {
      return <Navigate to="/" />;
    }
    return <ComposedComponent {...props} />;
  };
  return <Component />;
};

export default withoutAuthenticationHO;
