import React from "react";

import clsx from "clsx";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import style from "../../../assets/css/homeowner/auth.module.css";

import { homeownerForgotPasswordSchema } from "../validation";
import { homeownerForgotPassword } from "../../../shared/api/homehub";
import FormError from "../../../shared/components/form-control/FormError";
import ServerErrors from "../../../shared/components/server-error";
import Loader from "../../../shared/components/loader/Loader";

import { ReactComponent as UserIcon } from "../../../assets/images/new-login/user.svg";

import AppLabel from "../../../shared/components/white-label/AppLabel";

const ForgotPassword = () => {
  const {
    mutate: homeownerForgotPwdMutate,
    isLoading: homeownerForgotPwdIsLoading,
    error: homeownerForgotPwdError,
    isError: homeownerForgotPwdIsError,
  } = useMutation((email) => homeownerForgotPassword(email), {
    onError: (error) => {
      toast.error(error.response.data.message);
    },
    onSuccess: (res) => {
      toast.success(res.data.message);
    },
  });

  const { handleSubmit, handleChange, errors, values } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: homeownerForgotPasswordSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      homeownerForgotPwdMutate(values);
    },
  });

  return (
    <div
      className={clsx(style.auth, "max-w-none overflow-y-auto max-h-screen")}
    >
      <div className="max-w-[22.75rem] mx-auto">
        <AppLabel />
        <div
          className={
            "lg:h-[calc(100vh-88px)] maxLg:py-20 flex items-center flex-col justify-center"
          }
        >
          <h1 className="md:text-3xl text-xl text-yankeesblue font-bold">
            Forgot Your Password?
          </h1>
          <form className="mt-6 pr-1 pb-7 w-full" onSubmit={handleSubmit}>
            <div className={style.inputGroup}>
              <div className={style.authInput_homehub}>
                <UserIcon className={style.authInputIcon_homehub} />
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  className={clsx({ "is-error": errors.email })}
                  value={values.email}
                />
              </div>
              <FormError error={errors.email} />
            </div>
            {homeownerForgotPwdIsError && (
              <ServerErrors errors={homeownerForgotPwdError} />
            )}

            <button
              type="submit"
              className={"btn-auth_ho px-6"}
              disabled={homeownerForgotPwdIsLoading}
            >
              {homeownerForgotPwdIsLoading && (
                <Loader className="justify-center mr-3" />
              )}
              Send Verification code
            </button>
            <br />
            <div className={style.inputGroup}>
              <Link
                to={"/homehub/login"}
                className="text-bondiblue text-sm underline mx-auto my-1"
              >
                Or sign in here{" "}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
