import api from "..";

export const homeownerLoginApi = (data) => api.post("homehub/auth/login", data);

export const homeownerLogout = (data) => api.post("homehub/auth/logout", data);

export const homeownerSignupApi = (data) => api.post("homehub/signup", data);
export const contactSignupApi = (data) => api.post("/contact", data);

export const homeownerDataFetch = (unique_hash) =>
  api.get(`/contact?unique_hash=${unique_hash}`);

export const homeownerForgotPassword = (data) =>
  api.post("homehub/auth/password/email", data);

export const homeownerResetPassword = (data) =>
  api.post("homehub/auth/reset/password", data);

export const homeownerCheckPassword = (data) =>
  api.post("homehub/check-password", {
    unique_hash: data,
  });

export const homeownerSetPassword = (data) =>
  api.post("homehub/set-password", data);

export const homeownerAddProperty = (data) =>
  api.post("homehub/properties", data);

export const getHomeownerProperties = (page, search) =>
  api.get("homehub/home-owner-properties", {
    params: { page, search },
  });

export const getHomeOwnerRequest = (page, type) =>
  api.get("/homehub/home-owner-requests", {
    params: { page, type },
  });

export const homeownerUpdateProfile = (data) =>
  api.post("homehub/profile", data);

export const homeownerDeleteProfile = (data) =>
  api.post("homehub/profile", data);

export const homeownerDelete = (id) => api.delete(`homehub/${id}`);

export const getHomeownerPropertyDetail = (id) =>
  api.get(`/homehub/property/${id}`);

export const getMortgageRateDetails = (propertyDetail) =>
  api.get(`/mortgage-rate-alert/${propertyDetail}`);

export const updateMortgageRateDetails = (id, data) => {
  api.patch(`/mortgage-rate-alert/${id}`, data);
};
export const updateMortgageRateDetailsForAgent = (id, data) => {
  api.patch(`/mortgage-rate-alert/${id}`, data);
};

export const getMortgageRateDetailsForAgent = (propertyDetail, id) =>
  api.get(`/mortgage-rate-alert/${propertyDetail}?id=${id}`);

export const saveHomeOwnerRequest = (data) =>
  api.post("homehub/home-owner-request", data);

export const getTodayMortgageRate = () =>
  api.get(`/homehub/today-mortgage-rate`);

export const checkhomeOwnerRequestClick = (data) =>
  api.post("homehub/home-owner-request-click", data);

export const homehubMarkPropertyAsPrimary = (propertyDetail) =>
  api.post(`/homehub/primary-property/${propertyDetail}`);

export const homeownerOfferListing = (page) =>
  api.get("/offers", {
    params: { page },
  });
export const AgentOfferListing = (page) =>
  api.get("agents/homeowner-offers", {
    params: { page },
  });

export const homeownerMortageRateChange = (data, propertyDetailId) =>
  api.post(`/homehub/property/${propertyDetailId}/mortgage-rate`, data);

export const homeownerPropertyDelete = (propertyId) =>
  api.delete(`homehub/property/${propertyId}`);

export const uploadImage = (data, propertyId) =>
  api.post(`homehub/property/${propertyId}/images`, data);
