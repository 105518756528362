import React, { useState } from "react";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import clsx from "clsx";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";

import styles from "../../../assets/css/signup.module.css";

import { useRedirect } from "../../../store/redirect";
import { loginSchema } from "./validation";
import { setToken } from "../../../shared/helpers/util";
import ServerErrors from "../../../shared/components/server-error";
import Loader from "../../components/loader/Loader";

import logo from "../../../assets/images/Logo3.png";
import viewon from "../../../assets/images/eye.svg";
import viewoff from "../../../assets/images/eye-off.svg";
import FormError from "../../components/form-control/FormError";

const Login = ({
  onSubmit,
  forgotPasswordUrl,
  title = "Welcome back to Upside",
}) => {
  const navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState(false);
  const redirect = useRedirect((state) => state.redirect);
  const resetRedirect = useRedirect((state) => state.resetRedirect);
  const [params] = useSearchParams();

  const {
    mutate: userLogin,
    isLoading,
    error,
    isError,
  } = useMutation((contact) => onSubmit(contact), {
    onSuccess: (res) => {
      setToken("Token", res?.data?.token);
      navigate(
        redirect?.isRedirect
          ? redirect.url
          : params.has("onBoarding")
          ? "/?onBoarding"
          : "/"
      );
      resetRedirect();
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      userLogin(values);
    },
  });

  if (isLoading) {
    return (
      <Loader className="h-screen justify-center items-center flex text-2xl loader-content" />
    );
  }

  return (
    <>
      <section
        className={clsx(
          styles.custom_container,
          styles.custom_card,
          "h-screen"
        )}
      >
        <Link to="/">
          <img src={logo} alt="logo-img" className="mt-4 mb-8 w-44" />
        </Link>

        <div className={styles.card}>
          <div>
            <div className={styles.cardtext}>
              <h2 className="title_lg mb-2 text-[1.563rem] leading-[2.375rem]">
                {title}
              </h2>
            </div>
            <div>
              <form
                className={styles.inputRight}
                onSubmit={formik.handleSubmit}
              >
                <div className="maxMd:mb-3 mb-4">
                  <input
                    type="text"
                    name="email"
                    className={clsx(styles.form_control, "!pr-0", {
                      "border border-red-500": formik.errors.email,
                    })}
                    placeholder="Email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <FormError error={formik.errors.email} />
                </div>
                <div className={clsx(styles.view_icon, "maxMd:mb-3 mb-4")}>
                  <input
                    type={passwordShown ? "text" : "password"}
                    className={clsx(styles.form_control, {
                      "border border-red-500": formik.errors.password,
                    })}
                    placeholder="Password"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />

                  {passwordShown && (
                    <button
                      type="button"
                      onClick={() => setPasswordShown(false)}
                    >
                      <img src={viewoff} alt="eye-icon" />
                    </button>
                  )}
                  {!passwordShown && (
                    <button
                      type="button"
                      onClick={() => setPasswordShown(true)}
                    >
                      <img src={viewon} alt="view-icon" />
                    </button>
                  )}
                  <FormError
                    className={"mt-0"}
                    error={formik.errors.password}
                  />
                </div>
                {isError && <ServerErrors errors={error} />}
                <button
                  type="submit"
                  className={clsx(
                    "btn btn-primary maxMd:mt-6 mt-1 w-full flex justify-center items-center gap-2 py-6",
                    { disabled: isLoading }
                  )}
                >
                  <span>Login</span>
                  {isLoading && <Loader />}
                </button>
                <p
                  onClick={() => navigate(forgotPasswordUrl)}
                  className="text-base mt-5 text-steelblue font-medium me-2 cursor-pointer"
                >
                  Forgot Password?
                </p>
                {/* {!location?.pathname.includes("admin") &&
                  !location?.pathname.includes("agent") && (
                    <p
                      onClick={() => navigate("/agents/login")}
                      className="text-base mt-3 text-steelblue font-medium cursor-pointer"
                    >
                      Are you an agent? Log in here
                    </p>
                  )} */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
