import React, { useRef } from "react";

import clsx from "clsx";
import MaskedInput from "react-text-mask";

import Style from "../../../../../../assets/css/agent/auth.module.css";

import { phoneMask } from "../../../../../../shared/api/data";
import { AGENT_TYPE_TEAM } from "../../../../../../shared/helpers/constants";
import OnboardingTitle from "../../../../../../shared/components/agent-auth-title";
import { useOnBoarding } from "../../../../../../shared/provider/OnBoardingProvider";

import FormInput from "../../../../../../shared/components/agent-form-control/FormInput";
import FormLabel from "../../../../../../shared/components/agent-form-control/FormLabel";
import FormError from "../../../../../../shared/components/form-control/FormError";
import FormButton from "../../../../../../shared/components/agent-form-control/FormButton";
import FormSubdomainInput from "../../../../../../shared/components/agent-form-control/FormSubdomainInput";
import { debounce } from "lodash";

const AgentOnboardingStepFour = () => {
  const {
    formik,
    handleNextStep,
    domainAvailabilityMessage,
    domainSuggestionFn,
    domainCheckFn,
    isErrorDomainCheck,
  } = useOnBoarding();

  const executeSearch = useRef(
    debounce(async (fn, value) => {
      await fn(value);
    }, 3000)
  ).current;

  const onFirstNameChange = async (e) => {
    formik.handleChange(e);

    if (e.target.value.length === 0) {
      return;
    }
    await executeSearch(domainSuggestionFn, { name: e.target.value });
  };

  const onDomainChange = async (e) => {
    const value = e.target.value.trim().toLowerCase().replace(/\s+/g, "");

    formik.setFieldValue("domain", value);

    if (value.length === 0) return;

    await executeSearch(domainCheckFn, {
      domain: `${value}.${process.env.REACT_APP_HOST_NAME}`,
    });
  };

  const handleNext = (event) => {
    event.preventDefault();

    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        return;
      }
      if (isErrorDomainCheck) return;
      handleNextStep();
    });
  };

  return (
    <div className="max-w-[26.625rem] mx-auto mt-20">
      <OnboardingTitle
        title="Enter your team info"
        subTitle="Let’s finish setting up your account. Choose the user type that matches you."
      />
      <form className={Style.formLayout} onSubmit={handleNext}>
        <div className="mb-4">
          <FormLabel title="Team Name" className={Style.formLabel} />
          <FormInput
            type="text"
            name="team_name"
            placeholder="Enter team name"
            className={clsx(Style.formInput, {
              "is-error": formik.errors?.team_name,
            })}
            value={formik.values.team_name}
            onChange={onFirstNameChange}
          />
          <FormError error={formik.errors.team_name} />
        </div>
        {formik?.values?.type === AGENT_TYPE_TEAM && (
          <div className="mb-4">
            <div className="flex items-center">
              <FormLabel title="Subdomain" className={Style.formLabel} />
              {formik.values.domain?.length > 0 &&
                !formik.errors.domain &&
                domainAvailabilityMessage && (
                  <p
                    className={clsx("text-xs pl-1 text-green-600 mt-[0.7rem]", {
                      "!text-red-600": isErrorDomainCheck,
                    })}
                  >
                    ({domainAvailabilityMessage})
                  </p>
                )}
            </div>
            <FormSubdomainInput
              type="text"
              name="domain"
              inputWrapper={clsx({
                "is-error": formik.errors?.domain || isErrorDomainCheck,
              })}
              className="block w-full outline-0 p-4 rounded-lg"
              placeholder="Enter subdomain"
              value={formik.values.domain.trim("").toLowerCase()}
              onChange={onDomainChange}
            />
            <FormError error={formik.errors.domain} />
          </div>
        )}
        <div className="mb-4">
          <FormLabel
            title="Main Phone # for Team"
            className={Style.formLabel}
          />
          <MaskedInput
            mask={phoneMask}
            guide={false}
            type="text"
            name="team_phone"
            placeholder="Enter main team phone #"
            className={clsx(Style.formInput, {
              "is-error": formik.errors?.team_phone,
            })}
            value={formik.values.team_phone}
            onChange={formik.handleChange}
          />
          <FormError error={formik.errors.team_phone} />
        </div>
        <FormButton
          type="submit"
          className={Style.formButton}
          title="Save & Continue"
        />
      </form>
    </div>
  );
};

export default AgentOnboardingStepFour;
