import React from "react";

import clsx from "clsx";

const FormSubdomainInput = ({
  type,
  name,
  inputWrapper,
  className,
  placeholder,
  value,
  onChange,
  onBlur,
}) => {
  return (
    <div
      className={clsx(
        inputWrapper,
        "relative z-0 flex border hover:border-darkturquoise focus-within:border-darkturquoise rounded-lg hover:shadow-agentsAuthInputs focus-within:shadow-agentsAuthInputs transition mt-2"
      )}
    >
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        className={className}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <div className="inset-y-0 left-0 flex items-center px-2.5 text-gray-700 bg-[#F5F5F5] rounded-r-lg border-l">
        .withupside.com
      </div>
    </div>
  );
};

export default FormSubdomainInput;
