import React from "react";

import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

const OverlayStaticChart = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
    plugins: {
      tooltip: {
        enabled: true,
        backgroundColor: "#192841",
        padding: 8,
        titleFontSize: 30,
        displayColors: false,
        caretSize: 0,
        callbacks: {
          title: () => "",
          label: (tooltipItem) => {
            return `$${tooltipItem.parsed.y.toLocaleString()}`;
          },
        },
      },

      legend: {
        display: true,
        position: "bottom",
        padding: 20,
        labels: {
          boxWidth: 0,
          font: {
            size: 13,
          },
          color: "rgba(47, 162, 185, 1)",
        },
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
        pointLabels: {
          display: false,
        },
      },
    },
  };

  const labels = ["June - 2014", "February - 2016", "December - 2020"];

  const data = {
    labels: labels,
    borderSkipped: false,
    datasets: [
      {
        label: "",
        data: [10, 25, 30],
        pointRadius: 4,
        lineTension: 0.5,
        pointBorderWidth: 6,
        fill: true,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, "rgba(47, 162, 185, 0.12)");
          gradient.addColorStop(1, "rgba(47, 162, 185, 0)");
          return gradient;
        },
        borderColor: "#2FA2B9",
      },
    ],
  };
  return (
    <div className="relative h-[180px]">
      <Line options={options} data={data} />
      <h3 className="text-[#2FA2B9] absolute bottom-20 left-0 right-0 text-[13px] font-medium text-center">
        Request a more detailed home valuation
      </h3>
    </div>
  );
};
export default OverlayStaticChart;
