import React, { useState } from "react";

import OnboardingImage from "./steps/OnboardingImage";
import InvestorOnboardingStepOne from "./steps/onboarding-step-one";
import InvestorOnboardingStepTwo from "./steps/onboarding-step-two";
import InvestorOnboardingProgressBar from "./steps/InvestorOnboardingProgressBar";

const InvestorSignUp = () => {
  const [step, setStep] = useState(1);
  const [signupUserData, setSignupUserData] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company_name: "",
    property_criterias: {},
  });

  const onBackOnBoarding = () => {
    setStep(1);
  };

  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <>
            <section className="flex justify-between h-full bg-white maxLg:relative min-h-screen maxLg:p-6">
              <OnboardingImage />
              <div className="h-screen lg:w-1/2 w-full overflow-y-auto px-9 pb-9 maxLg:bg-white maxLg:z-[1] maxLg:rounded-xl max-w-none   max-h-screen maxLg:max-h-[calc(100vh-3rem)] relative">
                <InvestorOnboardingProgressBar stepNumber={step} />
                <InvestorOnboardingStepOne
                  setStep={setStep}
                  setSignupUserData={setSignupUserData}
                  signupUserData={signupUserData}
                />
              </div>
            </section>
          </>
        );
      case 2:
        return (
          <>
            <section className="flex justify-between h-full bg-white maxLg:relative min-h-screen maxLg:p-6">
              <OnboardingImage />
              <div className="h-screen lg:w-1/2 w-full overflow-y-auto px-9 pb-9 maxLg:bg-white maxLg:z-[1] maxLg:rounded-xl max-w-none   max-h-screen maxLg:max-h-[calc(100vh-3rem)] relative onboarding_inner">
                <InvestorOnboardingProgressBar
                  stepNumber={step}
                  onBackOnBoarding={onBackOnBoarding}
                />
                <InvestorOnboardingStepTwo
                  setStep={setStep}
                  signupUserData={signupUserData}
                />
              </div>
            </section>
          </>
        );
      default:
        return <></>;
    }
  };

  return <>{renderSteps()}</>;
};

export default InvestorSignUp;
