import { object, string } from "yup";

export const homeownerSignupSchema = object({
  first_name: string().trim().required("First name is required."),
  last_name: string().trim().required("Last name is required."),
  email: string()
    .trim()
    .email("Invalid email format.")
    .required("Email is required."),
  phone: string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid phone format.")
    .required("Phone is required."),
  address_data: object().required("Address data is required.").nullable(),
  password: string().trim().required("Password is required."),
});

export const homeownerLoginSchema = object({
  email: string()
    .trim()
    .email("Invalid email format.")
    .required("Email is required."),
  password: string().required("Password is required."),
});

export const homeownerResetPasswordSchema = object({
  password_confirmation: string()
    .trim()
    .required("Confirmation password is required."),
});

export const homeownerSetPasswordSchema = object({
  password: string().trim().required("Password is required."),
  password_confirmation: string()
    .trim()
    .required("Confirmation password is required."),
});
export const AddContactSchema = object({
  first_name: string()
    .trim()
    .matches(/^[a-zA-Z0-9]+$/, "Enter valid first name.")
    .required("First name is required."),
  last_name: string()
    .trim()
    .matches(/^[a-zA-Z0-9]+$/, "Enter valid last name.")
    .required("Last name is required."),
  email: string()
    .trim()
    .matches(/^[^!@]+@[^!@]+\.[^!@]+$/, "Enter valid email.")
    .email("Invalid email address")
    .required("Email is required."),
  phone: string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Enter valid phone #.")
    .required("Phone# is required."),

  // address_data: object()
  //   .shape({
  //     address: string().trim(),
  //   })
  //   .required("Address data is a required field")
  //   .nullable(),
});

export const homeownerForgotPasswordSchema = object({
  email: string().email("Invalid email format.").required("Email is required."),
});

export const homeownerPropertyRequestSchema = object({
  name: string().trim().required("Name is required."),
  email: string()
    .trim()
    .email("Invalid email format.")
    .required("Email is required."),
  phone: string()
    .required("Phone is required.")
    .matches(/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, "Invalid phone number."),
});
