import React from "react";

import { forgotPassword } from "../../../../shared/api/seller";

import ForgotPassword from "../../../../shared/auth/forgot-password/ForgotPassword";

const SellerForgotPassword = () => {
  return <ForgotPassword onSubmit={forgotPassword} loginUrl="/sellers/login" />;
};

export default SellerForgotPassword;
