import React, { useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { debounce } from "lodash";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";

import { getContactAddress } from "../../api/agent";
import PortalWrapper from "../portal-wrapper/PortalWraper";

import close from "../../../assets/images/close.png";
import { HOMEOWNER } from "../../helpers/constants";

const ReApiLocationPortal = ({
  className = null,
  style = {},
  placeholderName,
  setFieldValue,
  isError,
  classNamePrefix,
  defaultvalue = null,
  addressIndex,
  removeLocation,
  showRemoveButton,
  searchType = null,
  userType,
}) => {
  const [selectedOption, setSelectedOption] = useState({
    value: defaultvalue,
    label: defaultvalue?.title,
  });
  const [searchAddressKeyword, setSearchAddressKeyword] = useState("");
  const [locationData, setLocationData] = useState([]);

  const locationRef = useRef();

  const onInputChangeAddress = (newValue) => {
    setLocationData([]);

    setSearchAddressKeyword(newValue);
  };
  const onChangeAddress = (option) => {
    setSelectedOption(option);

    if (userType === HOMEOWNER) {
      setFieldValue("address", option ? option?.value : {});
    } else {
      setFieldValue(`addresses[${addressIndex}]`, option ? option?.value : {});
    }
  };

  const { refetch: addressDataRefetch, isFetching: isFetchingAddressData } =
    useQuery(
      ["get-address"],
      () => getContactAddress(searchAddressKeyword, searchType ?? ["A"]),

      {
        enabled: searchAddressKeyword.length >= 3,

        onSuccess: (res) => {
          setLocationData(res?.data);
        },
      }
    );

  const executeSearch = useRef(
    debounce(async () => {
      await addressDataRefetch();
    }, 300)
  ).current;

  useEffect(() => {
    if (searchAddressKeyword.length >= 3) {
      executeSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchAddressKeyword]);

  // useEffect(() => {
  // if (userType === HOMEOWNER) {
  //   setFieldValue("address", defaultvalue ? defaultvalue : {});
  // } else {
  //   setFieldValue(
  //     `addresses[${addressIndex}]`,
  //     defaultvalue ? defaultvalue : {}
  //   );
  // }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedOption]);

  const customNoOptionsMessage = () => {
    if (searchAddressKeyword.length >= 3) {
      return "No options available.";
    } else {
      return "Type 3 letters to see options.";
    }
  };

  useEffect(() => {
    setSelectedOption({
      value: defaultvalue,
      label: defaultvalue?.title,
    });
    if (userType === HOMEOWNER) {
      setFieldValue("address", defaultvalue ? defaultvalue : {});
    } else {
      setFieldValue(
        `addresses[${addressIndex}]`,
        defaultvalue ? defaultvalue : {}
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultvalue]);
  return (
    <>
      <PortalWrapper ref={locationRef}>
        {() => (
          <Select
            onChange={onChangeAddress}
            options={locationData?.map((option) => ({
              value: option,
              label: option.title,
            }))}
            defaultValue={
              defaultvalue && { value: defaultvalue, label: defaultvalue.title }
            }
            value={{
              value: selectedOption?.value,
              label: selectedOption?.label,
            }}
            // isDisabled={defaultvalue !== null}
            placeholder={placeholderName}
            isLoading={isFetchingAddressData}
            isClearable={false}
            onInputChange={onInputChangeAddress}
            isSearchable={true}
            classNamePrefix={classNamePrefix}
            style={style}
            className={clsx("w-full cursor-pointer", className, {
              "is-error rounded-lg": isError,
            })}
            noOptionsMessage={customNoOptionsMessage}
            menuPosition="fixed"
            menuPlacement="auto"
            menuShouldScrollIntoView={false}
            menuPortalTarget={document.body}
          />
        )}
      </PortalWrapper>
      {showRemoveButton && (
        <button
          type="button"
          className="absolute top-[6px] right-3 bg-transparent p-1 rounded"
          onClick={() => removeLocation(addressIndex)}
        >
          <img
            className=" object-cover cursor-pointer w-[20px] h-[20px]"
            src={close}
            alt="close-icon"
          />
        </button>
      )}
    </>
  );
};

export default ReApiLocationPortal;
